// Redux.
import { connect } from 'react-redux';

// React state and router.
import { useEffect, useState } from 'react'

// Components.
import { getAnalytics, logEvent } from "firebase/analytics";
import AppStoreLink from '../components/AppStoreLink';
import { IOS_APP_STORE_LINK, LABEL_TAGLINE } from '../constants/labels';


const QRCodeScan = ({ }) => {

    const analytics = getAnalytics();
    useEffect(() => {
        logEvent(analytics, 'qr_code_scan');
        window.location.replace(IOS_APP_STORE_LINK);
    }, [analytics]);

    return (
        <div>
            <div className='center' style={{ textAlign: 'center' }}>
                <a href='/'>
                    <div>
                        <img style={{ width: '240px', marginBottom: '20px' }} src='/appname2.png' />
                    </div>
                    <div className='tagline' style={{ color: 'black', margin: '0px auto', maxWidth: '280px', fontWeight: '500', fontSize: '24px' }}>
                        {LABEL_TAGLINE}
                    </div>
                </a>
                <div style={{ textAlign: 'center', padding: '30px 0px'}}>
                    <img src='/qrcode.png' style={{ width: '240px' }} />
                </div>
                <AppStoreLink />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(QRCodeScan);