import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

// Relative time.
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { LABEL_APPNAME } from '../constants/labels';

// Redux.
import { connect } from 'react-redux';

// Components.
import HomeHeader from './HomeHeader';
import NextUpEpisodes from './NextUpEpisodes';
import ForMePreview from './ForMePreview';

import AppStoreLink from './AppStoreLink';
import SearchBoxWithSuggestions from './SearchBoxWithSuggestions';
import PodcastAlbumSlider from './PodcastAlbumSlider';
import FirstUserPreviews from './FirstUserPreviews';
import useWindowDimensions from './windowHelper';
import { RAINBOW } from '../constants/colors';


const HomeContent = ({
    // Redux.
    authenticated,
}) => {
    const analytics = getAnalytics();

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        dayjs.extend(relativeTime);
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'home'
        });
    }, []);

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    const querySelected = (query) => {
        window.location.href = `/search/${encodeURIComponent(query)}`;
    }

    const loginClick = () => {
        logEvent(analytics, 'login_click');
        navigate('/login');
    }

    const searchClick = () => {
        logEvent(analytics, 'search_click');
        navigate('/search');
    }

    return (
        <div id="homeContent" className='container hideScrollbar'>
            <div>
                {!authenticated ?
                    <div>
                        <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                            {/* <div style={{ margin: '20px', textAlign: 'right' }}>
                                <button onClick={searchClick} style={{ textAlign: 'right' }}>
                                    <SvgIcon sx={{ stroke: "#ffffff", strokeWidth: 0.6 }} >
                                        <Search style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                                    </SvgIcon>
                                </button>
                            </div> */}

                            <div style={{ padding: '10px 0px 30px' }}>
                                <div style={{ padding: '0px 0px 30px', overflow: 'hidden' }}>
                                    <PodcastAlbumSlider />
                                </div>
                                <div style={{ lineHeight: '1.0'}}>
                                    <img className='appName' src='/appname2.png' />
                                </div>
                                <div style={{ textAlign: 'center', padding: '30px 0px 15px' }}>
                                    <AppStoreLink />
                                </div>
                                <div>
                                    <button className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Log in or sign up
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '40px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0px auto', maxWidth: '340px' }}>
                                    <div className='homePreview'>
                                        <div>Find your next favorite</div>
                                        <div>podcast in seconds.</div>
                                    </div>
                                    <div style={{ marginTop: '30px', padding: '30px', borderRadius: '20px', background: `${RAINBOW[4]}24` }}>
                                        <div>
                                            <img src="/movieclapper.png" style={{ width: '60px' }} />
                                        </div>
                                        <div style={{ margin: '6px 0px 6px', fontSize: '22px', fontWeight: '550'}}>
                                            Previews
                                        </div>
                                        <div className='homePreviewSubtitle'>
                                            <div>Preview podcasts</div>
                                            <div>before diving in.</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '20px', padding: '30px', borderRadius: '20px', background: `${RAINBOW[5]}24` }}>
                                        <div>
                                            <img src="/bookpages.png" style={{ width: '60px' }} />
                                        </div>
                                        <div style={{ margin: '6px 0px 6px', fontSize: '22px', fontWeight: '550'}}>
                                            Sections
                                        </div>
                                        <div className='homePreviewSubtitle'>
                                            <div>Jump to the section</div>
                                            <div>that interests you.</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '20px', padding: '30px', borderRadius: '20px', background: `${RAINBOW[6]}24` }}>
                                        <div>
                                            <img src="/sparklesrectanglestack.png" style={{ width: '60px' }} />
                                        </div>
                                        <div style={{ margin: '6px 0px 6px', fontSize: '22px', fontWeight: '550'}}>
                                            Recommendations
                                        </div>
                                        <div className='homePreviewSubtitle'>
                                            <div>Powered by</div>
                                            <div>podcast lovers and AI.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
{/* 
                        <div style={{ padding: '30px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0px auto', maxWidth: '340px' }}>
                                    <div className='homePreview'>
                                        Popular Previews
                                    </div>
                                </div>
                                <div style={{ margin: '30px 20px 30px' }}>
                                    <FirstUserPreviews />
                                </div>
                                <button style={{ marginBottom: '10px' }} className='homeButtonLight' onClick={searchClick}>
                                    <span style={{ verticalAlign: 'bottom' }}>
                                        Search speakers and topics
                                    </span>
                                </button>
                            </div>
                        </div> */}

                        <div style={{ padding: '30px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ paddingBottom: '0px' }}>
                                    <img style={{ maxWidth: `${Math.min(width, 400) - 40}px` }} src="/d1.png" />
                                </div>
                                <div style={{ paddingBottom: '0px' }}>
                                    <img style={{ maxWidth: `${Math.min(width, 400) - 40}px` }} src="/d2.png" />
                                </div>
                                <div style={{ paddingBottom: '20px' }}>
                                    <img style={{ maxWidth: `${Math.min(width, 400) - 40}px` }} src="/d3.png" />
                                </div>

                                <div style={{ textAlign: 'center', padding: '10px 0px 15px' }}>
                                    <AppStoreLink />
                                </div>
                                <div>
                                    <button style={{ marginBottom: '10px' }} className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Get podcasts for you
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ fontSize: '13px', padding: '20px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <span style={{ padding: '0px 13px' }}>
                                    <a style={{ color: 'rgb(113, 113, 113)' }} href='/terms.html'>Terms of Use</a>
                                </span>
                                <span style={{ padding: '0px 13px' }}>
                                    <a style={{ color: 'rgb(113, 113, 113)' }} href='/privacy.html'>Privacy Policy</a>
                                </span>
                                <span style={{ padding: '0px 13px' }}>
                                    <a style={{ color: 'rgb(113, 113, 113)' }} href='/support'>© {LABEL_APPNAME}</a>
                                </span>
                            </div>
                        </div>

                        {/* <div style={{ background: 'rgb(250, 250, 250)', padding: '16px', textAlign: 'center', fontSize: '13px', color: 'rgb(113, 113, 113)' }}>
                            <span style={{ padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/terms.html'>Terms of Use</a>
                            </span>
                            <span style={{ padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/privacy.html'>Privacy Policy</a>
                            </span>
                            <span style={{ padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/support'>© {LABEL_APPNAME}</a>
                            </span>
                        </div> */}
                    </div>
                    :
                    <div>
                        <HomeHeader />
                        <div style={{ padding: '10px 20px 10px' }}>
                            <SearchBoxWithSuggestions querySelected={querySelected} initialQuery={''} />
                        </div>
                        {NoQueryView(navigateToUrl)}
                    </div>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(HomeContent);


const NoQueryView = (navigateToUrl) => {
    return (
        <div style={{ fontSize: '16px' }}>
            <div>
                <div>
                    <div style={{ padding: '10px 0px 20px' }}>
                        <div style={{ padding: '0px 20px 13px' }}>
                            <span className='heading' style={{ verticalAlign: 'middle' }}>
                                Previews
                            </span>
                        </div>

                        <ForMePreview />
                    </div>

                    <NextUpEpisodes navigateToUrl={navigateToUrl} />
                </div>
            </div>
        </div>
    );
}

